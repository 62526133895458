import { Button, Chip, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { compact, flatten, flow, map, sortBy, uniq } from 'lodash/fp';

import Layout from '../components/Layout';
import TWEETS from './tweets.json';
import Tweet from '../components/Tweet';
import { graphql } from 'gatsby';
import { grey } from '@material-ui/core/colors';

const getTags = (tweets) =>
  flow(
    map((tweet) => tweet.tags),
    flatten,
    compact,
    uniq,
    sortBy((a) => a)
  )(tweets);

const Tweets = ({ data }) => (
  <>
    {data.map((tweet) => (
      <Tweet key={tweet.content} {...tweet} />
    ))}
  </>
);

const Filters = ({ filters, onClick, selected }) => {
  return (
    <div>
      {filters.map((filter) => (
        <Chip
          key={filter}
          label={filter}
          variant={'outlined'}
          onClick={() => onClick(filter)}
          color={filter !== selected ? 'default' : 'secondary'}
          style={{ marginRight: '4px', marginBottom: '4px' }}
        />
      ))}
    </div>
  );
};

export default (props) => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props;

  const [filter, setFilter] = useState(undefined);
  const tags = getTags(TWEETS);

  const toggleFilter = (filterNew) => {
    setFilter(filterNew === filter ? null : filterNew);
  };

  return (
    <Layout location={location} title={title}>
      <Typography
        variant={'h2'}
        style={{
          fontWeight: 'bold',
          lineHeight: 1,
          padding: '72px 0',
        }}
      >
        Short Form
      </Typography>
      <a href={'https://twitter.com/rohovdmytro'} target={'_blank'}>
        <Button
          size={'large'}
          variant={'outlined'}
          style={{ margin: '16px 0px' }}
          fullWidth
        >
          Follow me on Twitter
        </Button>
      </a>
      <br />

      <Filters filters={tags} onClick={toggleFilter} selected={filter} />
      <br />
      <Tweets
        data={TWEETS.filter((tweet) => {
          if (filter) {
            return !!tweet.tags && tweet.tags.includes(filter);
          } else {
            return true;
          }
        })}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
