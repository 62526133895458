import { Divider, Paper, Typography } from '@material-ui/core';
import { grey, purple, red } from '@material-ui/core/colors';

import React from 'react';

export default ({ content, tags }) => {
  return (
    <div style={{ marginBottom: '40px' }}>
      <div
        style={{
          padding: '0px 0px 2px 16px',
          backgroundColor: 'unset',

          borderLeft: `3px solid ${grey[400]}`,
        }}
      >
        <Typography variant={'subtitle2'}>{content}</Typography>
      </div>
      <Typography
        variant={'body2'}
        color={'textSecondary'}
        style={{
          marginTop: '2px',
          marginLeft: '16px',
          textTransform: 'lowercase',
        }}
      >
        {!!tags ? tags.map((t) => `#${t}`).join(', ') : null}
      </Typography>
    </div>
  );
};
